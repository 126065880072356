import { FC } from 'react';
import styles from './form-header.module.scss';
import { formatPrice } from '@/shared/lib/utils';
interface FormHeaderProps {
  localized_name: string;
  totalPrice: number | string;
  beautifulNumber?: string;
  old_price?: number;
  promotion_period?: string;
  isHomeInternet?: boolean;
}

export const FormHeader: FC<FormHeaderProps> = ({
  localized_name,
  totalPrice,
  beautifulNumber,
  old_price,
  promotion_period,
  isHomeInternet,
}) => {
  return (
    <div className={styles.container}>
      {beautifulNumber ? (
        <div className={styles.numbers__wrapper}>
          <span className={styles.numbers__title}>Красивый номер</span>
          <span className={styles.title}>{beautifulNumber}</span>
        </div>
      ) : (
        <span className={styles.title}>
          {isHomeInternet ? '' : 'Тариф '}
          {localized_name}
        </span>
      )}

      <div className={styles.price__wrapper}>
        {old_price ? (
          <>
            <div className={styles.price__values}>
              <span className={styles.price__old}>{old_price}</span>
              <span className={styles.price__current}>{formatPrice(totalPrice)}</span>
            </div>
            {promotion_period && <span className={styles.title__subtitle}>{promotion_period}</span>}
          </>
        ) : (
          <>
            {beautifulNumber && <span className={styles.title__subtitle}>Тариф {localized_name}</span>}
            <span className={styles.price__current}>{formatPrice(totalPrice)}</span>
          </>
        )}
      </div>
    </div>
  );
};
