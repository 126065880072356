import { FC } from 'react';
import { TariffService, TariffServiceProps } from '@/shared/ui-kit/tariff-service';
import styles from './basket-services.module.scss';

interface BasketServicesProps {
  services: TariffServiceProps[];
}

export const BasketServices: FC<BasketServicesProps> = ({ services }) => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>Услуги</span>
      {services.map((item, index) => (
        <TariffService key={`${item.title}-${index}`} {...item} />
      ))}
    </div>
  );
};
