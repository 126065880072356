import { ShoppingCart } from '@/shared/assets/svg';
import { Button } from '@mantine/core';
import { FC } from 'react';
import styles from './shopping-button.module.scss';

interface ShoppingButtonProps {
  handler: () => void;
  price: number | string;
  shortLabel?: boolean;
}

export const ShoppingButton: FC<ShoppingButtonProps> = ({ handler, shortLabel, price }) => {
  return (
    <Button
      onClick={handler}
      classNames={{
        root: styles.root,
        label: shortLabel ? styles.label__short : styles.label__long,
      }}
      leftIcon={<ShoppingCart />}
    >
      {!shortLabel ? 'Заказать за ' : ''} {price}
    </Button>
  );
};
