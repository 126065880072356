import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Tooltip } from '@/shared/ui-kit';
import { formatPrice } from '@/shared/lib/utils';
import Info from '@/shared/assets/svg/info-small.svg';
import styles from './basket-footer.module.scss';

interface BasketFooterProps {
  price?: number;
  priceWithPromotion?: number;
  promotionPeriod?: string;
  promotionDescription?: ReactNode;
  isMainPage?: boolean;
}

export enum PRICE_CONATANTS {
  TITLE = 'К оплате',
  WITHOUT_PROMOTION = 'Без скидки',
  WITH_PROMOTION = 'Со скидкой',
}

export const BasketFooter: FC<BasketFooterProps> = ({
  price,
  priceWithPromotion,
  promotionPeriod,
  promotionDescription,
  isMainPage,
}) => {
  return priceWithPromotion ? (
    <div className={cn(styles.container, { [styles.container__main]: isMainPage })}>
      <div className={styles.title}>{PRICE_CONATANTS.TITLE}</div>
      <div className={cn(styles.row, styles.row__first)}>
        <span className={styles.row__title}>{PRICE_CONATANTS.WITHOUT_PROMOTION}</span>
        <span className={styles.price__old}>{`${formatPrice(price)} /мес.`}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.row__title}>{PRICE_CONATANTS.WITH_PROMOTION}</span>
        <span className={styles.price__actual}>{`${formatPrice(priceWithPromotion)} /мес.`}</span>
      </div>
      {priceWithPromotion && promotionPeriod && (
        <div className={styles.promotion__container}>
          <span className={styles.promotion__period}>{promotionPeriod}</span>
          {promotionDescription && (
            <Tooltip label={promotionDescription} width={300} withinPortal={false}>
              <div className={styles.promotion__info}>
                <Info />
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  ) : (
    <div className={cn(styles.container, { [styles.container__main]: isMainPage })}>
      <div className={styles.row}>
        <span className={styles.title}>{PRICE_CONATANTS.TITLE}</span>
        <span className={styles.price__base}>{`${formatPrice(price)} /мес.`}</span>
      </div>
    </div>
  );
};
