import { FC } from 'react';
import { TariffCardOptionProps } from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option.types';
import TariffCardOption from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option';
import styles from './basket-options.module.scss';

interface BasketOptionsProps {
  options?: TariffCardOptionProps[];
  title?: string;
}

export const BasketOptions: FC<BasketOptionsProps> = ({ options, title }) => (
  <div className={styles.container}>
    {title && <span className={styles.title}>{title}</span>}
    {options?.map((item, index) => <TariffCardOption key={`${item.text}-${index}`} {...item} purple />)}
  </div>
);
