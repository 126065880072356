import { FC, memo } from 'react';
import styles from './selected-item.module.scss';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { OnlinePayment, SalesPointIcon, Truck, HomeIcon } from '@/shared/assets/svg';
import cn from 'classnames';

export enum SELECTED_ICON {
  POINT = 'point',
  DELIVERY = 'delivery',
  PAYMENT = 'payment',
  CONNECTION = 'connection',
}

interface SelectedItemProps {
  icon: SELECTED_ICON;
  label: string;
  value?: string;
  confirmation?: boolean;
}

const IconMapper = {
  point: <SalesPointIcon />,
  delivery: <Truck />,
  payment: <OnlinePayment />,
  connection: <HomeIcon />,
};

const SelectedItem: FC<SelectedItemProps> = memo(({ icon, label, value, confirmation }) => {
  const { isMobile } = useMediaQuery();

  return (
    <div className={cn(styles.wrapper, { [styles.confirmation]: confirmation })}>
      <div className={styles.icon}>{IconMapper[icon]}</div>
      <div>
        <span className={styles.label}>{label}</span>
        {icon === SELECTED_ICON.POINT && isMobile ? <br /> : ' '}
        {icon === SELECTED_ICON.DELIVERY && ' '}
        {value && <span className={styles.value}>{value}</span>}
      </div>
    </div>
  );
});

SelectedItem.displayName = 'SelectedItem';
export { SelectedItem };
