import { FC } from 'react';
import { RichText, Tooltip } from '@/shared/ui-kit';
import { Button } from '@mantine/core';
import Info from '@/shared/assets/svg/info-small.svg';
import { Cross, Restore, ServiceInfo } from '@/shared/assets/svg';
import styles from './tariff-service.module.scss';

export interface TariffServiceProps {
  title: string;
  servicePrice?: string | number;
  description?: string;
  added?: boolean;
  onChange: () => void;
}

export const TariffService: FC<TariffServiceProps> = ({ title, servicePrice, description, added, onChange }) => {
  return (
    <div className={styles.serviceBox}>
      <div className={styles.serviceBox__left}>
        <div className={styles.serviceBox__leftTop}>
          <div className={added ? styles.serviceBox__title : styles.serviceBox__titleRemoved}>
            {title}
            <div className={styles.serviceBox__tooltip}>
              {description && (
                <Tooltip
                  label={
                    <div className={styles.option__tooltip}>
                      <span className={styles.option__tooltip__title}>{title}</span>
                      <RichText whiteText className={styles.option__tooltip__text}>
                        {description}
                      </RichText>
                    </div>
                  }
                  withinPortal={false}
                >
                  <div className={styles.option__info}>{added ? <Info /> : <ServiceInfo />}</div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className={added ? styles.serviceBox__price : styles.serviceBox__priceRemoved}>
          {servicePrice !== null && (typeof servicePrice === 'string' ? servicePrice : `${servicePrice} ₽ /мес`)}
          {!added && <span className={styles.removed}>Услуга удалена</span>}
        </div>
      </div>
      <Button onClick={onChange} className={styles.restoredButton}>
        {added ? <Cross /> : <Restore />}
      </Button>
    </div>
  );
};
