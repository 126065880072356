import { FormInitialValues } from '@/widgets/form/constants';
import { UseFormReturnType } from '@mantine/form';
import { DELIVERY_ID, ITEM_TYPE_ID, ORDER_PARAMS, PAYMENT_TYPE, TYPE_DELIVERY_ID } from './constants';
import { CreateOrderArgs } from '@/store/api/order/order.type';
import { SliceBeautifulNumber } from '@/store/slice/beautiful-number';
import { addHours, format } from 'date-fns';

interface createOrderRequestMapperArgs {
  form: UseFormReturnType<FormInitialValues>;
  tariffName: string;
  totalPrice: number;
  servicesIds: number[];
  beautifulNumber: SliceBeautifulNumber | null;
  trplId?: number;
  work_time: string;
}

export const createOrderRequestMapper = ({
  form,
  tariffName,
  totalPrice,
  servicesIds,
  beautifulNumber,
  trplId,
  work_time,
}: createOrderRequestMapperArgs): CreateOrderArgs => {
  const items = [];

  const date = form?.values?.info?.exactTime === 'true' ? form?.values?.info?.date : null;

  const dateFrom = date ? format(date, 'y-MM-dd HH:mm') : null;
  const dateTo = date ? format(addHours(date, 1), 'y-MM-dd HH:mm') : null;

  trplId &&
    items.push({
      item_type_id: ITEM_TYPE_ID.TARIFF,
      id: trplId,
    });

  if (form.values.delivery === 'true') {
    items.push({
      item_type_id: ITEM_TYPE_ID.SERVICE,
      id: DELIVERY_ID,
    });
  }
  servicesIds.forEach((serv) => {
    items.push({
      item_type_id: ITEM_TYPE_ID.SERVICE,
      id: serv,
    });
  });

  if (beautifulNumber) {
    items.push({
      item_type_id: ITEM_TYPE_ID.BEAUTIFUL_NUMBER,
      id: beautifulNumber.pht_id,
      product: beautifulNumber.msisdn,
    });
  }

  return {
    source_id: ORDER_PARAMS.SOURCE_ID,
    payment_system_id: ORDER_PARAMS.PAYMENT_SYSTEM_ID,
    client: {
      name: form.values.info.name.trim(),
      contact_phone: Number(form.values.info.contact_phone),
      email: form.values.info.email || null,
    },
    total_cost_nds: totalPrice,
    delivery_address: {
      address: form.values.delivery === 'true' ? form.values.info.address?.trim() : form.values.info.point,
      type_delivery_id: form.values.delivery === 'true' ? TYPE_DELIVERY_ID.DELIVERY : TYPE_DELIVERY_ID.PICKUP,
      work_time: work_time,
    },
    items: items,
    payment_type: PAYMENT_TYPE.ONLINE,
    callback_start_datetime: dateFrom,
    callback_end_datetime: dateTo,
    order_flow_type: ORDER_PARAMS.ORDER_FLOW_TYPE,
  };
};
